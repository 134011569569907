






































































































































































































import useApi from "@/use/api";
import useAttachment from "@/use/attachment";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  PropType,
  computed,
} from "@vue/composition-api";
import moment from "moment";
import { nextTick } from "process";

export default defineComponent({
  components: {
    OProtocolModal: () => import("@/components/organisms/o-protocol-modal.vue"),
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    MUserInput: () => import("@/components/molecules/m-user-input.vue"),
    AFilesList: () => import("@/components/atoms/a-files-list.vue"),
  },
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    vehicle: {
      type: Object as PropType<any>,
      required: false,
      default: undefined,
    },
    details: {
      type: Object,
      required: false,
      default: undefined,
    },
    company: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { root, emit }) {
    const { uploadAttachments, downloadAttachment } = useAttachment({ root });
    const { axiosInstance } = useApi({ root });

    const form = ref();

    const state = reactive({
      error: null as null | string,
      loading: false,
      dialog: false,
      dialogKey: 0,
      isFormValid: false,
    });

    const model = reactive({
      type: "vehicleHandover",
      date: null as string | null,
      user: null as any,
      attachments: [] as File[],
      protocols: [] as File[],
    });

    const rules = {
      date: [(v: any) => !!v || "Podaj datę"],
      user: [(v: any) => !!v || "Wybierz użytkownika"],
    };

    const headers = [
      { value: "user", text: "Użytkownik", sortable: false },
      { value: "handoverDate", text: "Data wydania", sortable: false },
      {
        value: "handoverAttachments",
        text: "Protokoły wydania",
        sortable: false,
      },
      { value: "returnDate", text: "Data zwrotu", sortable: false },
      { value: "returnAttachments", text: "Protokoły zwrotu", sortable: false },
      { value: "actions", text: "", align: "end", sortable: false, width: 1 },
    ];

    const items = ref([]);

    const expanded = ref([]);

    const fetchAssignments = () => {
      if (props.id) {
        state.loading = true;

        axiosInstance
          .get(`inventory/vehicle/${props.id}`)
          .then(({ data: { assignments } }) => {
            items.value = assignments;
          })
          .catch(() => {
            items.value = [];
          })
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetchAssignments);
    watch(() => props.id, fetchAssignments);

    const resetForm = () => {
      model.type = "vehicleHandover";
      model.date = null;
      model.user = null;
      model.attachments = [];
      model.protocols = [];
    };

    const closeDialog = () => {
      state.dialog = false;
      nextTick(resetForm);
      setTimeout(() => (state.dialogKey = new Date().getTime()), 500);
    };

    const handoverVehicle = () => {
      state.dialog = false;
      state.dialogKey = new Date().getTime();

      model.type = "vehicleHandover";
      model.date = moment().format("YYYY-MM-DD");

      nextTick(() => (state.dialog = true));
    };

    const returnVehicle = (assignment: any) => {
      state.dialog = false;
      state.dialogKey = new Date().getTime();

      model.type = "vehicleReturn";
      model.date = moment().format("YYYY-MM-DD");
      model.user = assignment.user;

      nextTick(() => (state.dialog = true));
    };

    const onSubmit = async () => {
      await form.value?.validate();
      state.error = null;

      if (state.isFormValid) {
        state.loading = true;

        const formData = {
          type: model.type ?? undefined,
          user: model.user ? model.user.id : undefined,
          vehicle: props.id ?? undefined,
          date: model.date || undefined,
          attachments: undefined,
        };

        await uploadAttachments([...model.attachments, ...model.protocols])
          .then(({ data }) => {
            formData.attachments = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch(console.log);

        await axiosInstance
          .post("inventory", formData)
          .then(() => {
            fetchAssignments();
            emit("update");
            nextTick(closeDialog);
          })
          .catch(() => (state.error = "Nie można wykonać akcji"))
          .finally(() => (state.loading = false));
      }
    };

    const protocolVariables = computed(() => ({
      type: model.type as "vehicleReturn" | "vehicleHandover",
      user: model.user,
      vehicle: props.vehicle,
      company: props.vehicle?.company,
      handoverDate: model.date as string,
      returnDate: model.date as string,
    }));

    const addProtocols = (documents: File[]) => {
      model.protocols = [...documents];
    };

    return {
      downloadAttachment,

      form,

      state,

      model,
      rules,

      headers,
      items,
      expanded,

      resetForm,
      closeDialog,

      handoverVehicle,
      returnVehicle,

      onSubmit,

      protocolVariables,
      addProtocols,
    };
  },
});
